<template>
  <div id="homepage">
    <!-- ======= Header ======= -->
    <header
      id="header"
      class="fixed-top d-flex align-items-center header-transparent"
    >
      <site-header />
    </header>
    <!-- End Header -->

    <!-- ======= Hero Section ======= -->
    <Carousel
      :titlePage="'Chi tiết đơn vị'"
    />
    <!-- End Hero -->

    <main
      id="main"
    >
      <section
        class="team"
        data-aos="fade-up"
        data-aos-easing="ease-in-out"
        data-aos-duration="500"
      >
        <div
          id="enterprise-detail"
          class="container"
        >

          <div class="container news-detail-page">
            <div class="d-flex">
              <feather-icon
                icon="HomeIcon"
                size="20"
                color="#0282CD"
              />
              <feather-icon
                icon="ChevronRightIcon"
                size="20"
                class="ml-1"
              />
              <div class="titlePage">
                Doanh nghiệp
              </div>
              <feather-icon
                icon="ChevronRightIcon"
                size="20"
                class="ml-1"
              />
              <div class="titlePage">
                {{ dataDetail.name }}
              </div>
            </div>
            <section>
              <b-row>
                <b-col cols="8">
                  <b-card
                    header-bg-variant="primary"
                    border-variant="light"
                    header="Thông tin đơn vị"
                    header-text-variant="white"
                    class="mt-4 animate__animated animate__fadeInUp"
                  >
                    <b-card-body>
                      <div class="card-body-home-page">
                        <div class="w-100">
                          <h4>Thông tin</h4>
                          <b-row class="m-1 justify-content-between">
                            <b-col>
                              <feather-icon
                                icon="ListIcon"
                                size="16"
                                class="mr-1 icon-card"
                              />
                              <span>Mã doanh nghiệp</span>
                            </b-col>
                            <span>{{ dataDetail.code }}</span>
                          </b-row>
                          <b-row class="m-1 justify-content-between">
                            <b-col>
                              <feather-icon
                                icon="BriefcaseIcon"
                                size="16"
                                class="mr-1 icon-card"
                              />
                              <span>Tên doanh nghiệp</span>
                            </b-col>
                            <span class="text-truncate w-200"> {{ dataDetail.name }}</span>
                          </b-row>
                          <b-row class="m-1 justify-content-between">
                            <b-col>
                              <feather-icon
                                icon="ClipboardIcon"
                                size="16"
                                class="mr-1 icon-card"
                              />
                              <span>Mã số thuế</span>
                            </b-col>
                            <span>{{ dataDetail.taxNumber }}</span>
                          </b-row>
                          <b-row class="m-1 justify-content-between">
                            <b-col>
                              <feather-icon
                                icon="UserIcon"
                                size="16"
                                class="mr-1 icon-card"
                              />
                              <span>Người đại diện</span>
                            </b-col>
                            <span>{{ dataDetail.officalProfile }}</span>
                          </b-row>
                          <b-row class="m-1 justify-content-between">
                            <b-col>
                              <feather-icon
                                icon="FlagIcon"
                                size="16"
                                class="mr-1 icon-card"
                              />
                              <span class="text-truncate  w-50">Ngành nghề kinh doanh chính</span>
                            </b-col>
                            <span class="text-truncate  w-150">{{ dataDetail.bussinessSector }}</span>
                          </b-row>
                        </div>
                      </div>
                    </b-card-body>
                    <b-card-body>
                      <div class="card-body-home-page">
                        <div class="w-100">
                          <h4>Địa chỉ</h4>
                          <b-row class="m-1 justify-content-between">
                            <b-col>
                              <feather-icon
                                icon="MapPinIcon"
                                size="16"
                                class="mr-1 icon-card"
                              />
                              <span>Địa chỉ</span>
                            </b-col>
                            <span
                              class="text-truncate w-150"
                              :title="dataDetail.address"
                            >{{ dataDetail.address }}</span>
                          </b-row>
                          <b-row class="m-1 justify-content-between">
                            <b-col>
                              <feather-icon
                                icon="PhoneIcon"
                                size="16"
                                class="mr-1 icon-card"
                              />
                              <span>Số điện thoại</span>
                            </b-col>
                            <span>{{ dataDetail.phoneNumber }}</span>
                          </b-row>
                          <b-row class="m-1 justify-content-between">
                            <b-col>
                              <feather-icon
                                icon="MailIcon"
                                size="16"
                                class="mr-1 icon-card"
                              />
                              <span>Email</span>
                            </b-col>
                            <span class="text-truncate w-200">{{ dataDetail.email }}</span>
                          </b-row>
                          <b-row class="m-1 justify-content-between">
                            <b-col>
                              <feather-icon
                                icon="GlobeIcon"
                                size="16"
                                class="mr-1 icon-card"
                              />
                              <span>Website</span>
                            </b-col>
                            <span class="text-truncate w-50">{{ dataDetail.website }}</span>
                          </b-row>

                        </div>
                      </div>
                    </b-card-body>
                  </b-card>
                </b-col>
                <b-col
                  cols="4"
                  class="enterprise-top-container"
                >
                  <Search
                    class="search-new-input p-1 mt-3"
                    placeholder="Tìm kiếm"
                    @change="search($event)"
                  />
                  <div class="p-1">
                    <h3>
                      Danh sách đơn vị
                    </h3>
                    <div
                      v-for="item in dataTopList"
                      :key="item.id"
                      class="row mt-2 enterprise-top"
                    >
                      <a
                        class="col-4"
                        style="max-width: 80px;"
                        :href="'/chi-tiet-doanh-nghiep/' + item.id"
                      >
                        <img
                          class="enterprise-top-img"
                          :src="item.avatar ? ($serverfile + item.avatar) : imgDefault"
                        >
                      </a>
                      <a
                        class="col-8 enterprise-top-title ml-1"
                        :href="'/chi-tiet-doanh-nghiep/' + item.id"
                      >
                        <h4>{{ item.name }}</h4>
                        <h6 style="color: rgba(46, 58, 74,0.5);">{{ item.bussinessType }}</h6>
                      </a>
                    </div>
                  </div>

                </b-col>
              </b-row>

            </section>
          </div>

        </div>
      </section>
      <!-- End Team Section -->
    </main>
    <!-- End #main -->

    <!-- ======= Footer ======= -->
    <footer
      id="footer"
      data-aos="fade-up"
      data-aos-easing="ease-in-out"
      data-aos-duration="500"
    >
      <site-footer />

    </footer>
  </div>
</template>

<script>
import { formatFullName, formatDateToDDMM } from '@core/utils/filter'
import {
  BImg,
  BCard,
  BCol,
  BRow,
  BButton,
  VBTooltip,
  BFormInput,
  BCardBody,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import ButtonComponent from '@/components/button/ButtonComponent.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import Search from '@/components/search/Search.vue'
import Carousel from './components/Carousel.vue'
import SiteFooter from './components/SiteFooter.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from './constants/ConstantsApi'
import SiteHeader from './components/SiteHeader.vue'
import store from './store'

export default {
  components: {
    FeatherIcon,
    Search,
    BCol,
    BRow,
    SiteHeader,
    Carousel,
    SiteFooter,
    BCard,
    BCardBody,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  filters: {
    formatDateToDDMM,
  },
  data() {
    return {
      tokenCheck: localStorage.getItem('accessToken'),
      formatFullName,
      currentPage: 0,
      urlQuery: {
        pageNumber: 1,
        pageSize: 3,
      },
      totalRecord: 0,
      isActive: false,
      dataDetail: {},
      dataTopList: [],
      // eslint-disable-next-line global-require
      imgDefault: store.imgDefault,
    }
  },
  computed: {
    statusVariantType() {
      // eslint-disable-next-line default-case
      const typeColor = {
        License: 'light-primary',
        Confirm: 'light-success',
      }
      return color => typeColor[color]
    },
    statusVariant() {
      const statusColor = {
        NonLiscening: 'secondary',
        PendingLicensing: 'warning',
        Licensing: 'primary',
        RejectLicense: 'danger',
        PendingRenew: 'warning',
        PendingExtend: 'warning',
        Revoke: 'danger',
        Extend: 'success',
        Renew: 'primary',
      }
      return status => statusColor[status]
    },
  },
  mounted() {
    /* eslint-disable no-undef */
    /* eslint-disable no-new */
    // eslint-disable-next-line func-names
    /**
     * Easy selector helper function
     */
    const select = (el, all = false) => {
      el = el.trim()
      if (all) {
        return [...document.querySelectorAll(el)]
      }
      return document.querySelector(el)
    }

    /**
     * Easy event listener function
     */
    const on = (type, el, listener, all = false) => {
      const selectEl = select(el, all)
      if (selectEl) {
        if (all) {
          selectEl.forEach(e => e.addEventListener(type, listener))
        } else {
          selectEl.addEventListener(type, listener)
        }
      }
    }

    /**
     * Easy on scroll event listener
     */
    const onscroll = (el, listener) => {
      el.addEventListener('scroll', listener)
    }

    /**
     * Scrolls to an element with header offset
     */
    const scrollto = el => {
      const header = select('#header')
      let offset = header.offsetHeight

      if (!header.classList.contains('header-scrolled')) {
        offset -= 20
      }

      const elementPos = select(el).offsetTop
      window.scrollTo({
        top: elementPos - offset,
        behavior: 'smooth',
      })
    }

    /**
     * Toggle .header-scrolled class to #header when page is scrolled
     */
    const selectHeader = select('#header')
    if (selectHeader) {
      const headerScrolled = () => {
        if (window.scrollY > 100) {
          selectHeader.classList.add('header-scrolled')
        } else {
          selectHeader.classList.remove('header-scrolled')
        }
      }
      window.addEventListener('load', headerScrolled)
      onscroll(document, headerScrolled)
    }

    /**
     * Back to top button
     */
    const backtotop = select('.back-to-top')
    if (backtotop) {
      const toggleBacktotop = () => {
        if (window.scrollY > 100) {
          backtotop.classList.add('active')
        } else {
          backtotop.classList.remove('active')
        }
      }
      window.addEventListener('load', toggleBacktotop)
      onscroll(document, toggleBacktotop)
    }

    /**
     * Mobile nav toggle
     */
    // eslint-disable-next-line func-names
    on('click', '.mobile-nav-toggle', function (e) {
      select('#navbar').classList.toggle('navbar-mobile')
      this.classList.toggle('bi-list')
      this.classList.toggle('bi-x')
    })

    /**
     * Mobile nav dropdowns activate
     */
    // eslint-disable-next-line func-names
    on(
      'click',
      '.navbar .dropdown > a',
      // eslint-disable-next-line func-names
      function (e) {
        if (select('#navbar').classList.contains('navbar-mobile')) {
          e.preventDefault()
          this.nextElementSibling.classList.toggle('dropdown-active')
        }
      },
      true,
    )

    /**
     * Scrool with ofset on links with a class name .scrollto
     */
    // eslint-disable-next-line func-names
    on(
      'click',
      '.scrollto',
      // eslint-disable-next-line func-names
      function (e) {
        if (select(this.hash)) {
          e.preventDefault()

          const navbar = select('#navbar')
          if (navbar.classList.contains('navbar-mobile')) {
            navbar.classList.remove('navbar-mobile')
            const navbarToggle = select('.mobile-nav-toggle')
            navbarToggle.classList.toggle('bi-list')
            navbarToggle.classList.toggle('bi-x')
          }
          scrollto(this.hash)
        }
      },
      true,
    )

    /**
     * Skills animation
     */
    const skilsContent = select('.skills-content')
    if (skilsContent) {
      // eslint-disable-next-line no-new
      new Waypoint({
        element: skilsContent,
        offset: '80%',
        handler(direction) {
          const progress = select('.progress .progress-bar', true)
          progress.forEach(el => {
            el.style.width = `${el.getAttribute('aria-valuenow')}%`
          })
        },
      })
    }

    /**
     * Testimonials slider
     */
    // eslint-disable-next-line no-new
    new Swiper('.testimonials-carousel', {
      speed: 400,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
    })

    /**
     * Porfolio isotope and filter
     */
    window.addEventListener('load', () => {
      const portfolioContainer = select('.portfolio-container')
      if (portfolioContainer) {
        // eslint-disable-next-line no-undef
        const portfolioIsotope = new Isotope(portfolioContainer, {
          itemSelector: '.portfolio-wrap',
          layoutMode: 'fitRows',
        })

        const portfolioFilters = select('#portfolio-flters li', true)

        // eslint-disable-next-line func-names
        on(
          'click',
          '#portfolio-flters li',
          // eslint-disable-next-line func-names
          function (e) {
            e.preventDefault()
            portfolioFilters.forEach(el => {
              el.classList.remove('filter-active')
            })
            this.classList.add('filter-active')

            portfolioIsotope.arrange({
              filter: this.getAttribute('data-filter'),
            })
            portfolioIsotope.on('arrangeComplete', () => {
              // eslint-disable-next-line no-undef
              AOS.refresh()
            })
          },
          true,
        )
      }
    })

    /**
     * Initiate portfolio lightbox
     */
    // eslint-disable-next-line no-undef
    const portfolioLightbox = GLightbox({
      selector: '.portfolio-lightbox',
    })

    /**
     * Portfolio details slider
     */
    // eslint-disable-next-line no-new
    // eslint-disable-next-line no-undef
    new Swiper('.portfolio-details-slider', {
      speed: 400,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
    })

    /**
     * Animation on scroll
     */
    window.addEventListener('load', () => {
      // eslint-disable-next-line no-undef
      AOS.init({
        duration: 1000,
        easing: 'ease-in-out',
        once: true,
        mirror: false,
      })
    })
  },
  created() {
    if (this.$route.params.id) {
      this.fetchData(this.$route.params.id)
      this.fetchTopData(this.urlQuery)
    } else {
      this.$router.push({ name: 'error-404' })
    }
  },
  methods: {
    async fetchData(id) {
      const { data } = await axiosApiInstance.get(ConstantsApi.BUSSINESS_DETAIL, {
        params: { id },
      })
      this.dataDetail = data
    },
    async fetchTopData(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.BUSSINESS_GET_PAGING, {
        params: urlQuery,
      })
      this.dataTopList = data.data?.pageLists
      this.$hideLoading()
    },
    logout() {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
      localStorage.removeItem('userData')
      localStorage.removeItem('accessToken')
      this.$ability.update(initialAbility)
      this.$router.push({ name: 'home' })
      this.$router.go()
    },
  },
}
</script>

<style lang="scss">
#enterprise-detail {
  .enterprise-top-container {
    height: fit-content;
    background: #f8f8f8;
    .enterprise-top {
      .enterprise-top-img {
          width: 80px;
          height: 80px;
          object-fit: cover;
        }
        .enterprise-top-title {
          height: 10px;
        }
      }
  }
}
.w-150 {
  max-width: 50%;
}
.w-200 {
  max-width: 50%;
}
@media (max-width: 1362px){
  #enterprise-detail {
    padding: 0 !important;
    .news-detail-page{
      padding: 20px !important;
    }
    .enterprise-top-container {
    }
  }
}

@media (max-width: 1200px) {
  #enterprise-detail{
    .col-8 {
      flex: auto !important;
      max-width: max-content !important;
    }
  }
  .enterprise-top-container {
    width: 100%;
    flex: auto !important;
    max-width: 100% !important;
  }
}

@media (max-width: 750px) {
  .enterprise-top-container {
    width: 100%;
    flex: auto !important;
    max-width: 100% !important;
  }
  .w-200 {
    width: 200px;
  }
  .w-150{
    width: 150px;
  }
}
</style>
